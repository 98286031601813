var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',[_c('Heading',{attrs:{"heading":"h4","content":"Roster"}},[_c('template',{slot:"button"},[_c('router-link',{staticClass:"ml-2",attrs:{"to":{
            name: 'EducatorClassRosterEdit',
            params: { id: _vm.$route.params.id }
          }}},[_c('el-button',{attrs:{"type":"success","size":"mini"}},[_c('i',{staticClass:"fas fa-edit"}),_vm._v(" "+_vm._s(_vm.$t("TeacherCourses.Edit"))+" ")])],1)],1)],2),_c('hr'),_c('div',{staticClass:"ml-3"},[_c('Heading',{attrs:{"heading":"h6","content":"Student(s)"}}),(_vm.isStudent)?[_c('el-table',{staticClass:"mt-2",attrs:{"border":"","data":_vm.getMe(_vm.course.users)}},[_c('el-table-column',{attrs:{"label":_vm.$t('TeacherCourses.Students'),"prop":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{ name: 'UserProfile' }}},[_c('b',[_vm._v(" "+_vm._s(_vm.user.displayName( scope.row.first_name, scope.row.last_name ))+" ")])])]}}],null,false,1795476272)}),_c('el-table-column',{attrs:{"label":_vm.$t('login.email'),"prop":"email"}}),_c('el-table-column',{attrs:{"label":_vm.$t('TeacherCourses.Join Date')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.updated_at))+" ")]}}],null,false,339401641)})],1),_c('p',{staticClass:"text-info"},[_vm._v(" "+_vm._s(_vm.course.student_users.length - 1 > 1 ? `${_vm.course.student_users.length - 1} more students` : `${_vm.course.student_users.length - 1} more student`)+" ")])]:[_c('el-table',{staticClass:"mb-3 mt-2",attrs:{"border":"","data":_vm.course.users}},[_c('el-table-column',{attrs:{"label":_vm.$t('TeacherCourses.Students'),"prop":""},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('router-link',{attrs:{"to":{
                  name: _vm.isAdmin ? 'AdminViewUser' : 'TeacherViewUser',
                  params: { id: scope.row.id }
                }}},[_c('b',[_vm._v(" "+_vm._s(_vm.user.displayName( scope.row.first_name, scope.row.last_name ))+" ")])])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('login.email'),"prop":"email"}}),_c('el-table-column',{attrs:{"label":_vm.$t('TeacherCourses.Join Date')},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.instant.formatGoogleTime(scope.row.updated_at))+" ")]}}])})],1)]],2)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }